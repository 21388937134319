.BlogPage {
  font-family: 'Poppins', sans-serif;


  .admin-div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    padding: 20px auto;

    button{
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
      padding: 20px 40px;
      border: 0;
      background-color: #fff;
      border-radius: 4px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

      &:hover{
        background-color: #e5e4e2;
        font-weight:700;
      }
    }
  }

  .wrapper-div{
    padding: 80px 100px;
    @media screen and (max-width:750px) {
      padding: 80px 40px;
    }
  }

  .likes-div{
    margin: 0 0 40px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 40px;

    @media screen and (max-width:950px){
      .liked-2{
        display: none;
      }
    } 
  }

  

  .category-div{
    margin: 20px 0 20px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    .category-top{
      color: black;
      margin: 0;
      font-weight: 400;
      font-size: 16px;
      cursor: pointer;
      font-family: 'Poppins', sans-serif;

      &:hover{
        text-decoration: none;
        font-weight: 500;
      }
    }
  }


  //============COROUSEL =============================
  .blogsdiv{
    display: flex;
    flex-wrap: wrap;
    .corousel-div{
      padding: 40px 0 0 0;
      width: auto;
      position: relative;
      overflow-x: scroll;
      overflow-y: hidden;
      &::-webkit-scrollbar {
        width: 0px;
        background: transparent;
    }
  
      .blog-category{
        margin: 0;
        font-weight: 500;
        font-size: 24px;
      }
    }
  }

  div[data-arrow="right"] {
    position: absolute;
    top: 80px;
    right: -25px;
    height: 60px;
    width: 30px;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    z-index: 5;
    cursor: pointer;
    background-image: url(../../assets/right-arrow.png);
    background-repeat: no-repeat, no-repeat;
    background-position: right, left;       

    button{
      display: none;
    }
  }
  
  div[data-arrow="left"] {
    position: absolute;
    top: 80px;
    left: -25px;
    height: 60px;
    width: 30px;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    z-index: 5;
    cursor: pointer;
    background-image: url(../../assets/left-arrow.png);
    background-repeat: no-repeat, no-repeat;
    background-position: right, left; 

    button{
      display: none;
    }
  }


}

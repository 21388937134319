@import url('https://fonts.googleapis.com/css2?family=Baloo+Tammudu+2:wght@500&family=Mate+SC&family=Overpass&display=swap');
.password-reset{
    height:100vh;
    background-color: #cbafd8;
    padding-top: 78px;

    &__brandName{
         margin-bottom: 25px;
         display: flex;
         justify-content: center;
         padding-left: 3px;
        .heart-logo{
            width: 40px;
            height:40px;
            margin-top:0.7%;
        }
        .brand-name{
            padding-left: 16px;
            font-family: 'Overpass', sans-serif;
            margin-top: 0;
            margin-bottom: 0;
            letter-spacing: 4px;
            text-transform: uppercase;
            padding-top: 0.7%;
            font-weight: 600;
            font-size: 2.5rem;

            @media (max-width: 750px){
                padding-top: 2%;
                font-weight: 550;
                font-size: 2rem;
                padding-left: 9px;
                letter-spacing: 3px;
            }
        }
    }
    &__formbox{
        background-color: white;
        margin: auto;
        width: 30%;
        min-width:240px;
        padding: 20px 30px 25px;
        border-radius: 4px;
        box-shadow: 0px 0px 12px 2px rgba(15, 15, 15, 0.3);
        border-top: 2px solid #ac66cc;

        @media (max-width: 900px){
            width:40%;
        }
        
        .error-message{
            background: #F8D7DA;
            padding: 8px 0;
            border-radius: 5px;
            color: #ac66cc;
            border: 1px solid #F5C6CB;
            text-align: center;
            margin-bottom: 5%;
        }
        .reset-form-group{
            position: relative;
            padding-bottom: 20px;
            &__label{
                display: block;
                font-weight: 600;
                margin-bottom: 1rem;
            }
            &__togglepassword{
                width: 3.4rem;
                height: 3.4rem;
                position: absolute;
                right: 0px;
                z-index: 2;
                cursor: pointer;
                outline: 0px;
                border: 0px;
                background: transparent;
                @media (max-width:950px){
                    padding-left: 15px;
                    padding-right: 0;
                }
            }
            &__inputfield{
                width:100%;
                height: 3.4rem;
                padding: 0px calc(4.4rem) 0px 1rem;
                font-weight: 400;
                font-size: 1.3rem;
                cursor: text;
                outline: 0px;
                border: 1px solid rgb(199, 204, 211);
                border-radius: 4px;
                transition: all 250ms ease-in-out;

                &:hover {
                    background-color: #ffffff;
                    box-shadow: 0px 0px 14px 0.3px #cbafd85d;
                  }
        
                  &:focus {
                    outline: none;
                    box-shadow: 0px 0px 12px 0.8px #cbafd88e;
                  }
                @media( max-width:900px){
                    font-size: 0.8rem;
                }
            }
        }
        .password-reset__formbox__btnHolder{
            width:100%;
            padding-bottom: 27px;
            padding-top: 12px;

            &__btn{
                width: 100%;
                padding: 10px 15px;
                font-weight: 400;
                font-size: 1rem;
                text-transform: uppercase;
                line-height: normal;
                border-radius: 2px;
                cursor: pointer;
                outline: 0px;
                background-color: #ac66cc;
                border:none;
                color: rgb(255, 255, 255);
                @media (max-width :650px){
                    font-size: 0.8rem;
                    padding: 8px 10px;
                }
            }
        }
    }
}
.userDashboard {
    margin-top: 4.8rem;
    background-color: #fbf2f4;
    height: fit-content;
    padding-top: 0rem;
    display: flex;
    flex-wrap: wrap;
    font-family: Poppins;

    .userDashboard__detailsBox {
        background-color: #fff;
        margin: 2rem 4rem;
        border: 1px solid rgba(128, 128, 128, 0.048);
        border-radius: 5px;
        padding: 2rem 4rem;
        flex: 1 1;

        @media screen and (max-width: 640px) {
            width: 85%;        
            margin: 0;
            padding: 5%;

        }

        h4 {
            font-weight: 700;
        }

        .userDashboard__personalDetails {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: flex-start;
            flex-wrap: wrap;
            gap: 80px;

            button {
                background-color: #a6c;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                width: 140px;

                .MuiSvgIcon-root {
                    font-size: 20px;
                }
            }

            @media screen and (max-width: 640px) {
                width: 100%;
                padding: 5%;
                justify-content: center;
                gap: 40px;
            }

            h5 {
                font-weight: 700;
            }

            .detail__row {
                margin: 5px 0;
                width: 450px;
                .detail__head {
                    color: #a6c;
                    font-weight: 500;
                }
                @media screen and (max-width: 640px) {
                    width: fit-content;
                }
            }

            .detail__rowEdit {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 450px;
                margin: 5px 0;
                input, select {
                    width: 300px;
                }
                input:focus, input:active, select:focus, select:active {
                    border: 1px solid #a6c;
                }
                .detail__head {
                    color: #a6c;
                    font-weight: 500;
                }
                @media screen and (max-width: 640px) {
                    flex-direction: column;
                    width: fit-content;
                }
            }

            .editProfile__image {
                position: relative;

                .userDashboard__imageEdit {
                    background: #AB70CA;
                    border: none;
                    text-decoration: none;
                    color: white;
                    font-weight: 600;
                    font-size: 18px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    top: 85%;
                    left: 85%;
                    border-radius: 100%;
                    height: 40px;
                    width: 40px;
                }
            }

            img {
                height: 200px;
                width: 200px;
                object-fit: cover;
                border-radius: 10px;

                @media screen and (max-width: 640px) {
                    height: 250px;
                    width: 250px;
                }
            }
        }

        .userDashboard__sessions {
            display: flex;
            flex-wrap: wrap;
            column-gap: 10%;
            row-gap: 20px;
        }
    }
}
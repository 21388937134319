.activityPage {
  padding-bottom: 1rem;
  background-color: #fff2eb;

  .jumbotron {
    background: url("../../assets/emotions.jpg") no-repeat;
    background-position: center;
    background-size: cover;
    height: 100vh;
    padding: 0;
  }

  .give-mask {
    height: 100%;
  }

  .display-4 {
    font-size: 3rem;
  }

  .whit {
    font-weight: 500;
    text-transform: uppercase;
    color: #fff;
  }

  #jumbotronText {
    padding-top: 25vh;
    padding-bottom: 20vh;
  }

  .btn {
    color: #fff;
  }

  /* .card-image{
  height: 22rem;
  object-fit: cover;
  width:100%;
  margin:auto;
} */
  .emotionalH {
    color: #333;
    text-align: center;
    text-transform: uppercase;
    font-family: "Noto Sans", sans-serif;
    font-weight: bold;
    position: relative;
    margin-top: 2.875rem;
  }

  @media (max-width: 1199px) {
    .positivity {
      margin-top: 2rem;
    }
  }

  @media (max-width: 767px) {
    .emotionalH {
      font-size: 1.4rem;
    }
  }

  @media (max-width: 394px) {
    .emotionalH {
      font-size: 1.2rem;
    }
  }

  @media (max-width: 338px) {
    .emotionalH {
      font-size: 1rem;
    }
  }

  @media (max-width: 287px) {
    .emotionalH {
      font-size: 0.7rem;
    }
  }

  .emotionalU {
    border-bottom: 3px solid #db584e;
    text-decoration: none;
    padding-bottom: 0.3rem;
  }

  .column2 {
    margin-top: -5%;
    font-size: 0.969rem;
    text-align: justify;
  }

  .span1 {
    font-style: italic;
    font-weight: 600;
    color: rgba(51, 51, 51, 0.925);
  }

  .span1-1 {
    color: rgba(2, 1, 1, 0.555);
    font-style: italic;
    font-weight: 500;
  }

  .sign1 {
    color: red;
    font-size: 23px;
  }

  .sign2 {
    color: green;
    font-size: 23px;
  }

  .span2 {
    color: #e66c63;
    font-weight: 500;
  }

  .span2-2 {
    text-align: center;
    color: royalblue;
    font-size: 15.5px;
  }

  #positivity-text {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .container1 {
    position: relative;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }

  .card1 {
    position: relative;
    width: 270px;
    height: 350px;
    background: #fff;
    margin: 20px;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
    transition: 0.5s;
  }

  .circle1 {
    position: relative;
    width: 100%;
    height: 100%;
    background: #000;
    clip-path: circle(180px at center 0);
    text-align: center;
  }

  .worry {
    color: #fff;
    font-size: 2.2rem;
    padding: 30px 0;
  }

  .content1 {
    position: absolute;
    bottom: 10px;
    padding: 70px 10px 0px;
    text-align: center;
  }

  .read-more {
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    background: #000;
    color: #fff;
    border-radius: 40px;
    text-decoration: none;
    margin-top: 20px;
  }

  .read-more:hover {
    text-decoration: none;
    color: #fff;
    margin-bottom: 0.2rem;
    border-bottom: 1px solid #fff;
  }

  .amsg {
    color: #fff;
    font-size: 2rem;
    padding: 30px 0;
  }

  .vent {
    color: #fff;
    font-size: 2rem;
    padding: 30px 0;
  }

  .card1 .content1 p {
    color: #666;
  }

  .container1 .card1:nth-child(1) .circle1,
  .container1 .card1:nth-child(1) .content1 a {
    background: #ffaf00;
  }

  .container1 .card1:nth-child(2) .circle1,
  .container1 .card1:nth-child(2) .content1 a {
    background: #da2268;
  }

  .demo {
    padding-top: 2.188rem;
    padding-bottom: 2.188rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .demo .dange {
    height: 8vh;
    font-size: 20px;
    font-weight: 500;
    border-color: rgb(255, 115, 0);
    color: orangered;
  }

  .dange:hover {
    background: linear-gradient(235deg, #ff730071, #ff6905, #ff7f17c0);
    color: white;
    transition: 0.3s ease-in-out;
    transform: scale(1.05);
  }

  .carousel {
    margin: 50px auto;
    padding: 0 70px;
  }

  .carousel-item {
    color: #999;
    font-size: 14px;
    text-align: center;
    overflow: hidden;
    min-height: 290px;
  }

  .carousel .item .img-box {
    width: 135px;
    height: 135px;
    margin: 0 auto;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 50%;
  }

  .carousel .img-box img {
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 50%;
  }

  .carousel .testimonial {
    padding: 30px 0 10px;
  }

  .carousel .overview {
    font-style: italic;
  }

  .carousel .overview b {
    text-transform: uppercase;
    color: #db584e;
  }

  .carousel .carousel-control {
    width: 40px;
    height: 40px;
    margin-top: -20px;
    top: 50%;
    background: none;
  }

  .carousel-control i {
    font-size: 68px;
    line-height: 42px;
    position: absolute;
    display: inline-block;
    color: rgba(0, 0, 0, 0.8);
    text-shadow: 0 3px 3px #e6e6e6, 0 0 0 #000;
  }

  .carousel .carousel-indicators {
    bottom: -40px;
  }

  .carousel-indicators li,
  .carousel-indicators li.active {
    width: 10px;
    height: 10px;
    margin: 1px 3px;
    border-radius: 50%;
  }

  .carousel-indicators li {
    background: #999;
    border-color: transparent;
    box-shadow: inset 0 2px 1px rgba(0, 0, 0, 0.2);
  }

  .carousel-indicators li.active {
    background: #555;
    box-shadow: inset 0 2px 1px rgba(0, 0, 0, 0.2);
  }
}
.img-head {
  padding-top: 40px;
  margin-left: 90px;
}
.second-para {
  font-size: 16px;
  padding-right: 25px;
}
.first-para {
  padding-top: 25px;
}

.applyOffer {
    width: 100%;
    .applyOffer__header{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;

        @media screen and (max-width: 800px) {
            display: flex;
            flex-direction: column-reverse;
            gap: 20px;
            align-items: flex-start;
        }

        .applyOffer__text {
            img {
                height: 30px;
            }
        }
        .applyOffer__couponWarning {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            p {
                font-size: 12px;
                color: #bd2727;
                margin-bottom: 0;
                margin-top: 2px;
            }
            .applyOffer__customCoupon {
                display: flex;
                flex-direction: row;
                align-items: center;
                flex-wrap: wrap;
                @media screen and (max-width: 800px) {
                    width: 100%;
                }
            }
        }

        .applyOffer__couponApplied {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            text-align: center;
            gap: 5px;
            p {
                margin: 0;
                padding: 0;
            }
            svg {
                fill: #8B4E96;
                cursor: pointer;
            }
        }

        input {
            margin: 0 10px;
            padding: 6px;
            border: 1px solid #888888;
            border-radius: 5px;
            align-items: center;
            @media screen and (max-width: 800px) {
                width: 75%;
                margin: 0;
                margin-right: 10px;
            }
        }

        button {
            margin: 0;
            background-color: #a6c;
        }

        .applyOffer__text {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;
            gap: 10px;
            p {
                margin: 0;
                padding: 0;
            }
            #text {
                color: #8B4E96;
                font-weight: 600;
            }
        }
    }

    .coupon__desc{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 0px;

        button {
            background-color: #a6c;
        }

        .coupon__details{
            display: flex;
            flex-direction: column;
            margin-top: 8px;
            p {
                margin-bottom: 4px;
            }
        }

        .coupon__code {
            .applyOffer__coupon {
            position: relative;
            z-index:0;
                .text{
                    z-index: 1;
                    position: absolute;
                    left: 30%;
                    top:25%;
                    font-size: 18px;
                    color: white;
                }
                @media screen and (max-width: 300px) {
                    font-size: 18px;
                }
                @media screen and (max-width: 1100px) {
                    font-size: 14px;
                }
            }
        }
    }
}

.breathePage {
    background:
        url("../../assets/bg.jpg") no-repeat center center/cover;
    height: 100vh;
    display: flex;
    flex-flow: column;
    justify-content: space-evenly;
    align-items: center;

    /* circle animation */
    .wrapper {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: center;
        margin: auto;
        height: 200px;
        width: 200px;
        position: relative;
        transform: scale(1);
    }

    .circle {
        background-color: #010f1c;
        height: 200px;
        width: 200px;
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }

    .gradient-circle {
        background: conic-gradient(#55b7a4 0%,
                #4ca493 40%,
                #fff 40%,
                #fff 60%,
                #336d62 60%,
                #2a5b52 100%);
        height: 220px;
        width: 220px;
        z-index: -2;
        border-radius: 50%;
        position: absolute;
        top: -10px;
        left: -10px;
    }

    .pointer {
        background-color: #fff;
        border-radius: 50%;
        height: 20px;
        width: 20px;
        display: block;
    }

    .pointer-container {
        position: absolute;
        top: -40px;
        left: 140px;
        width: 20px;
        height: 190px;
        animation: rotate 7.5s linear forwards infinite;
        transform-origin: bottom center;
    }

    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(360deg);
        }
    }

    .wrapper.grow {
        animation: grow 3s linear forwards;
    }

    @keyframes grow {
        from {
            transform: scale(1);
        }

        to {
            transform: scale(1.2);
        }
    }

    .wrapper.shrink {
        animation: shrink 3s linear forwards;
    }

    @keyframes shrink {
        from {
            transform: scale(1.2);
        }

        to {
            transform: scale(1);
        }
    }
}
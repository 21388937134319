.bookedMhp__profile {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 100px;
    width: 100%;
    flex-wrap: wrap;

    @media screen and (max-width: 800px) {
        justify-content: center;
        gap: 30px;
    }

    .bookedMhp__image {
        width: 180px;
        height: 180px;
        border-radius: 100%;
        object-fit: cover;
    }

    .bookedMhp__info {
        @media screen and (max-width: 800px) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 30px;
        }
        .bookedTherapist__dateTime {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 20px;
            padding: 5px 0;
            h6 {
                text-align: center;
                padding: 0;
                margin: 0;
            }
            img {
                height: 20px;
            }
        }
    }
}
.meditationPage {
    background-color: rgb(251, 242, 244);
    padding-bottom: 1rem;

    .btn-primary {
        background-color: #007bff;
        border-color: white;
        color: black;
    }

    .btn-primary i {
        position: absolute;
        padding-left: 94%;
        margin-top: 7%;
    }

    .btn-primary:hover {
        color: black;
        border-color: white;
    }

    .btn-secondary i {
        padding-left: 5px;
    }

    .btn-secondary i:hover {
        border-color: white;
        color: rgba(160, 221, 211, 0.568);
        transform: scale(1.1);
    }

    .modal-title {
        text-align: center;
    }


    .modal-img {
        width: 100px;
        height: 100px;
    }

    /* main section */
    .custom-row {
        padding: 20px;
    }

    .tagline {
        font-family: "Sacramento", cursive;
        word-spacing: 0.5rem;
    }

    #vent-out-box {
        margin-top: 10rem !important;
        margin-bottom: 6rem !important;
    }

    /*=================*/
    .card-img-top {
        height: 60vh !important;
    }

    .mb-3 {
        margin-bottom: 0 !important;
    }

    @media (max-width: 768px) {
        .center {
            text-align: center;
        }

        .main-2 {
            margin: 1.3rem;
        }
    }
}
.EventShowPage {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  overflow-x: hidden;
  font-family: Poppins;

  p {
    font-size: 15px;
  }

  .landing-text {
    font-family: "Poppins";
    font-weight: 450 !important;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 20%;
    //  left: 115px;
    top: 195px;
    border-radius: nullpx;
  }

  .jumbotron {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 0;
    margin-top: 4rem;
    margin-bottom: 20px;
    img {
      width: 100%;
      height: 85vh;
      object-fit: contain;
    }
  }
  // .event__navbar {
  //   display: flex;
  //   flex-direction: row;
  //   flex-wrap: wrap;
  //   position: sticky;
  //   top: 75px;
  //   align-items: center;
  //   background-color: #ffffff;
  //   padding: 0px 25px 20px 0;
  //   width: 100%;
  //   margin-top: 1rem;
  // }

  // .event__navbar > div > a {
  //   display: flex;
  //   flex-direction: row;
  //   align-items: center;
  //   padding: 10px 0;
  // }

  // .event__navbar > div > a > h6 {
  //   padding: 0 10px;
  //   margin: 0;
  // }

  hr {
    margin-left: 0;
    width: 200px;
    height: 5px;
    border: none;
    background-color: #aa66cc;
  }

  .event__about {
    margin: 30px 0;
  }

  .event__titleInput {
    width: 100%;
  }

  .event__about > h4 {
    display: inline;
  }

  .event__time > h4 {
    display: inline;
  }

  .add__Calender {
    color: #aa66cc;
    margin-top: 40px;
  }

  .register {
    margin: 30px 0;
  }

  .register > button {
    background-color: #aa66cc;
  }

  .event__social {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    background-color: #ffffff;
    padding: 30px;
    width: 100%;
    margin-bottom: 70px;
  }
  .slick-dots li button:before {
    color: blueviolet;
    font-size: 10px;
  }
  .navsize:hover {
    border-bottom: 2px solid #aa66cc;
  }

  .navsize {
    border-bottom: 2px solid transparent;
  }
  .selected {
    border-bottom: 3px solid #aa66cc !important;
  }

  @media screen and (max-width: 820px) {
    .jumbotron > img {
      height: 100%;
    }
  }
}

.button-scroll-up {
    position: fixed; 
    width: 100%;
    bottom: 10px;
    height: 40px;
    left:45%;
    font-size: 3rem;
    z-index: 1;
    cursor: pointer;
    color: #aa66cc;
}

.bookingPage {
    width: 100vw;
    padding: 100px 150px;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 800px) {
        padding: 100px 20px;
    }
    .bookingPage__heading {
        margin-bottom: 60px;
        @media screen and (max-width: 800px) {
            margin-bottom: 30px;
            font-size: 24px;
        }
    }
    .bookingPage__content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 5%;
        .left {
            display: flex;
            flex-direction: column;
            width: 62.5%;
            gap: 60px;
            @media screen and (max-width: 800px) {
                width: 100%;
            }
        }
        .right {
            width: 32.5%;
            @media screen and (max-width: 800px) {
                width: 100%;
            }
        }
    }
}

.AppointmentsCard {
    width: 45%;
    border-radius: 5px;
    background: #FFFFFF;
    box-shadow: 3px 3px 44px rgba(0, 0, 0, 0.11);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 30px;
    padding: 20px;
    height: fit-content;

    @media screen and (max-width: 768px) {
        width: 100%;
        justify-content: center;
    }

    .mhp__profile {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 40px;
        width: 100%;
        @media screen and (max-width: 820px) {
            flex-direction: column;
            justify-content: center;
        }
    }

    .mhp__image {
        height: 8rem;
        width: 8rem;

        // @media screen and (max-width: 768px) {
        //     height: 8rem;
        //     width: 8rem;
        // }
    }

    .mhp__details {
        width: 100%;
        @media screen and (max-width: 768px) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        @media screen and (max-width: 820px) {
            text-align: center;
        }
        .btn {
            margin: 0;
            @media screen and (max-width: 768px) {
                padding: 5px;
            }
        }
        h5 {
            font-weight: 600;
        }
        p {
            margin: 0;
            font-size: 14px;
        }
    }
    .reschedule__actions {
        display: flex;
        flex-direction: column;

        @media screen and (max-width: 768px) {
            align-items: center;
        }

        .reschedule__warning {
            color: #E66161;
            @media screen and (max-width: 768px) {
                text-align: center;
            }
        }
    }
    .addToCalendar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        .addToCalendar__btn {
            @media screen and (max-width: 980px) {
                display: none;
            }
        }
        button {
            background-color: #a6c;
            width: fit-content;
            font-size: 12px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: 5px;
            .MuiSvgIcon-root {
                height: 0.7em;
                width: 0.7em;
            }
        }
    }
    .schedule__actions {
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;
        margin-bottom: 5px;
        button {
            background-color: #a6c;
            width: fit-content;
            font-size: 14px;
            padding: 5px 7px;
        }
        .schedule__rescheduled {
            opacity: 0.7;
            cursor: not-allowed;
            box-shadow: none;
        }
        .schedule__rescheduled:hover {
            box-shadow: none;
        }
        span {
            color: #a6c;
            font-size: 14px;
            cursor: pointer;
        }
        .reschedule__action {
            position: relative;
            padding: 7px 0;
            .required__dot {
                position: absolute;
                width: 9px;
                height: 9px;
                border-radius: 100%;
                background-color: #E66161;
                right: -3.5px;
                top: -1px;
            }
        }
        .reschedule__required {
            border: 1px solid #a6c;
            border-radius: 4px;
            text-decoration: none;
            padding: 7px;
            box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
        }
        .reschedule__required:hover {
            box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%);
        }
    }
}

.e-dlg-container{
    z-index: 2030 !important;
}

.e-dlg-container.e-dlg-center-center {
    position: fixed !important;
}

.e-dialog .e-dlg-header-content{
    background-color: #a6c !important;
    border-radius: 6px 6px 0 0 !important;
}

.e-dlg-header{
    color: #FFFFFF !important;
    text-align: center;
    font-family: Poppins;
    margin-left: 35px;
    font-size: 18px;
}

.e-dialog .e-footer-content{
    text-align: center;
}

.e-dialog .e-footer-content .e-btn{
    background-color: #a6c !important;
    border-color: #a6c !important;
    border-radius: 5px;
}

.e-dlg-overlay {
    position: fixed !important;
}

.e-btn-icon.e-icon-dlg-close.e-icons {
    color: #FFFFFF !important;
}

.rescheduleModal__button {
    width: 45%;
    font-family: Montserrat;
}

.container{
  max-width: 1260px;
}
.TherapistInfo {
  font-family: Poppins;
  .intro {
    width: 100%;
    margin-top: 5rem;
    justify-content: center;
    .buttonOnIntro{
      background-color: #aa66cc;
      color: white;
      border-radius: 6px;
      border: none;
      margin: 0.8rem 0.5rem;
      padding:  5px 14px 6px;
      font-size: 14px;
      border: 1px solid #aa66cc;
      transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
    .buttonOnIntro:hover{
      transform: scale(1.04);
    }
  }

  #therapistImage {
    object-fit: cover;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    margin: 2rem 2rem;
  }

  .introContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 2rem 0 0 0;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  .special {
    margin: 1.5rem 0;
  }

  .therapistInfoContent {
    margin: 1rem;
    width: 30%;
  }

  .icons {
    margin: 0 10px 0 0;
  }

  .specific {
    margin: 25px 15px 0 0;
  }

  .intro {
    display: flex;
    flex-direction: column;
  }

  #copyLink {
    display: none;
  }

  .padding0 {
    padding-left: 0;
  }
  @media screen and (min-width: 768px) and (max-width:1200px) {
    .therapistInfoContent{
      width: 50%;
      text-align: center;
    }
  }
  @media screen and (max-width: 768px) {
    .introContainer {
      flex-direction: column;
    }

    .therapistInfoContent {
      text-align: center;
      margin: 0;
      width: 100%;
    }
  }

  #about {
    h4 span {
      text-transform: uppercase;
    }
    .experience {
      font-weight: 600;
      font-size: 1.1rem;
      span {
        font-weight: 400;
        font-size: 1rem;
      }
    }
    @media screen and (max-width: 768px) {
      margin-top: 4rem;
    }
  }

  #work-experience {
    margin: 2rem 0;
    li {
      line-height: 2rem;
    }
  }

  #review {
    img {
      height: 1.3rem;
      border-radius: 50%;
    }
    p {
      margin-left: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }

  .therapist__social {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    padding: 30px;
    width: 100%;
  }

  .container > section > a {
    position: sticky;
    top: 0;
  }

  .container > center > span {
    display: inline-flex;
    display: -webkit-inline-box;
  }

  .specialList {
    display: flex;
    flex-wrap: wrap;
  }

  .specialisations {
    font-size: 14px;
    margin-top: 10px;
    margin-right: 10px;
    padding: 8px;
    border-radius: 20px;
    background-color: white;
    color: #aa66cc;
    border: 2px solid #aa66cc;
    display: flex;
  }

  .grayColor {
    color: #727272;
  }

  h6 {
    justify-content: center;
    font-weight: 500;
    span {
      padding-left: 5px;
      font-weight: 400;
      line-height: 1.4rem;
    }
  }
  .fees {
    font-size: 75%;
  }
}
.blogCard {
  padding: 0 !important;
  margin-top: 20px !important;
  margin-right: 40px;
  width: 300px;
  height: auto;

  .card-image {
    object-fit: cover;
    width: 100%;
    margin: 0;
    padding: 0 !important;
    //border-radius: 0.8rem 0.8rem 0 0;
  }

  .date-time-div{
    color:#999496;
    font-family: 'Poppins', sans-serif !important;
    display: flex;
    font-size: 12px;
    font-weight: 500;
    flex-direction: row;
    gap: 10px;
    align-items: center;

  }


  .view.overlay {
    //border-radius: 0.8rem 0.8rem 0 0 !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  .p-title {
    font-family: 'Poppins', sans-serif !important;
    font-weight: 500;
    text-align: left !important;
    margin: 0 !important;
  }

  display: flex;
  flex-direction: column;
  justify-content:flex-start;
  text-align: justify;
  text-justify: inter-word;

  transition: all .3s ease;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  transition-delay: 0s;

  &:hover{
    box-shadow: rgba(59, 59, 59, 0.45) 0px -25px 12px -20px;
  }

  
}

.like-div {
  .blogCard {
    .date-time-div {
      font-size: 15px;
    }

    .p-title {
      font-size: 20px;
    }
  }
}

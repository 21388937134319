.termsEditor{
    .ck.ck-content ul,
    .ck.ck-content ul li{
        list-style-type: inherit;
    }
    .ck.ck-content  ul,li{
        padding-left: 40px;
    }
    .ck.ck-content ol,
    .ck.ck-content ol li{
        list-style-type: decimal;
    }
    .ck.ck-content  ol,li{
        padding-left: 20px;
    }
    .ck.ck-content  p{
        margin-bottom: 0.5rem;
    }
    &-saveButton{
        display: flex;
        flex-direction: row-reverse;
        margin-top: 1%;
        margin-right: 1%;
        button{
            padding: 0.5% 1.2% ;
            background: rgba(0, 0, 255, 0.568);
            font-weight: bold;
            color:white;
        }
    }
}
.bookSession {
    background-color: white;

    .display-4 {
        font-size: 2rem;
        text-align: center;
        margin-top: 1rem;
    }

    .input {
        width: 100%;
    }
}
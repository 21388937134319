@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&display=swap');

.teamcard {
  //   display: flex;
  //   height: 100vh;
  // justify-content: center;
  // align-items: center;

  &__heading {
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    margin: 30px 0 30px 0;
    font-family: 'Poppins', sans-serif;
  }

  &__row {
    border-radius: 10px;
    max-width: 90%;
    margin: 0 auto 0 auto;
    //   margin: 0 120px 0 20px;
    box-shadow: 0 24px 24px 0 rgba(0, 0, 0, 0.05),
      0 2px 2px 0 rgba(0, 0, 0, 0.05), 0 4px 4px 0 rgba(0, 0, 0, 0.05),
      0 8px 8px 0 rgba(0, 0, 0, 0.05), 0 16px 16px 0 rgba(0, 0, 0, 0.05);
  }
  &__col2 {
    padding: 70px 48px 86px 46px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__info {
    font-family: 'Montserrat', sans-serif;
    font-style: italic;
    font-size: 18px;
    &::before {
      content: '\201C';
      margin: 0 11px -6px -7px;
      display: inline-block;
      vertical-align: bottom;
      font-size: 56px;
      font-weight: 800;
      line-height: 30px;
      color: #8862b3;
    }

    &::after {
      position: absolute;
      content: '\201D';
      margin: 10px -7px -12px -4px;
      display: inline-block;
      vertical-align: bottom;
      font-size: 56px;
      font-weight: 800;
      line-height: 30px;
      color: #8862b3;
    }
  }

  &__img {
    height: 400px;
    width: 100%;
    border-radius: 10px;
  }

  &__name {
    margin-bottom: 0;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 20px;
  }
  &__job {
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    font-weight: 400;
  }

  &__about {
    margin-top: 40px;
    width: 100%;
    display: flex;
    justify-content: space-around;

    &__info {
      display: flex;
      flex-direction: column;
    }

    &__text {
      display: block;
      text-align: center;
      color: #8862b3;
      font-size: 48px;
      line-height: 42px;
      font-weight: 500;
      margin-bottom: 10px;
    }
    &__subtext {
      font-family: 'Poppins', sans-serif;
      font-weight: 400;
    }
  }
}

.steps-card {
  height: 2px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 40px;
  left: 46px;
}
.step-card {
  background: 0 0;
  border: none;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  cursor: pointer;
  width: 34px;
  margin-right: 8px;
  height: 100%;
  padding: 10px 0;
  position: relative;

  &:focus,
  &:active {
    border: none;
    outline: none;
  }
  &:focus::after,
  &:active::after {
    background-color: #5d21d2;
  }

  &::after {
    content: '';
    background-color: #b3b1b9;
    position: absolute;
    width: 100%;
    height: 2px;
    top: 50%;
    left: 0;
  }
}

@media (max-width: 767.98px) {
  .teamcard__img {
    height: 250px;
    object-fit: cover;
  }
}
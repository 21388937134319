.ModalLogin {

    .MuiCircularProgress-root {
        height: 12px !important;
        width: 12px !important;
        margin-left: 10px;
    }

    .login-info {
        width: 100%;
        padding: 20px;
        color: white;
        background: linear-gradient(to right, rgb(167, 85, 222), rgb(245, 51, 126));
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 101;

        div {
            justify-content: center;
        }
    }

    h1 {
        font-weight: bold;
        margin: 0;
    }

    h2 {
        text-align: center;
    }

    p {
        font-size: 14px;
        font-weight: 100;
        line-height: 20px;
        letter-spacing: 0.5px;
        margin: 20px 0 30px;
    }

    .modal-content {
        border-radius: 10px;
    }

    span {
        font-size: 12px;
    }

    .link {
        color: #333;
        font-size: 18px;
        text-decoration: none;
        margin: 15px 0;

        &:hover {
            color: rgb(148, 147, 147);
        }
    }


    button {
        border-radius: 5px;
        border: 1px solid #6c757d;
        background-color: #b579d2;
        color:  white;
        font-size: 16px;
        padding: 8px 20px;
        transition: transform 80ms ease-in;
        font-family: 'Poppins';
    }

    button:active {
        transform: scale(0.95);
    }

    button:focus {
        outline: none;
    }

    form {
        background-color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        text-align: center;
    }

    input {
        background-color: #eee;
        border: none;
        padding: 12px 15px;
        margin: 8px 0;
        width: 100%;
    }

    .container {
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
            0 10px 10px rgba(0, 0, 0, 0.22);
        position: relative;
        overflow: hidden;
        max-width: 700px;
        min-height: 500px;
    }

    .form-container {
        position: absolute;
        top: 25px;
        height: 100%;
        transition: all 0.6s ease-in-out;
    }

    .sign-in-container {
        width: 50%;
        z-index: 2;
    }

    .container.right-panel-active .sign-in-container {
        transform: translateX(100%);
    }

    .sign-up-container {
        width: 50%;
        opacity: 0;
        z-index: 1;
    }

    .container.right-panel-active .sign-up-container {
        transform: translateX(100%);
        opacity: 1;
        z-index: 5;
        animation: show 0.6s;
    }

    @keyframes show {

        0%,
        49.99% {
            opacity: 0;
            z-index: 1;
        }

        50%,
        100% {
            opacity: 1;
            z-index: 5;
        }
    }

    .overlay-container {
        position: absolute;
        top: 25px;
        left: 50%;
        width: 50%;
        height: 100%;
        overflow: hidden;
        transition: transform 0.6s ease-in-out;
        z-index: 100;
    }

    .container.right-panel-active .overlay-container {
        transform: translateX(-100%);
    }

    .overlay {
        background: #FF416C;
        background: -webkit-linear-gradient(to right, #A755de, #f5337e);
        background: linear-gradient(to right, #A755de, #f5337e);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 0 0;
        color: #FFFFFF;
        position: relative;
        left: -100%;
        height: 100%;
        width: 200%;
        transform: translateX(0);
        transition: transform 0.6s ease-in-out;
    }

    .container.right-panel-active .overlay {
        transform: translateX(50%);
    }

    .overlay-panel {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0 40px;
        text-align: center;
        top: 0;
        height: 100%;
        width: 50%;
        transform: translateX(0);
        transition: transform 0.6s ease-in-out;
    }

    .overlay-left {
        transform: translateX(-20%);
    }

    .container.right-panel-active .overlay-left {
        transform: translateX(0);
    }

    .overlay-right {
        right: 0;
        transform: translateX(0);
    }

    .container.right-panel-active .overlay-right {
        transform: translateX(20%);
    }

    .social-container {
        margin: 20px 0;
    }

    .social-container a {
        border: 1px solid #DDDDDD;
        border-radius: 50%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin: 0 5px;
        height: 40px;
        width: 40px;
    }

    @media (max-width: 415px) {
        .container {
            min-height: 600px;
        }

        .form-container {
            top: 40px;
        }

        button {
            padding: 8px;
        }

        h1 {
            font-size: 2.2rem;
        }
      }
}
.firstSection {
    display: block;

    .contentWrapper {
        max-width: 1232px;
        margin: -200px auto 0;
        padding: 0 64px 96px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #f0ebf6;

        @media screen and (max-width: 600px) {
            padding-bottom: 50px;
            border: none;
        }

        @media screen and (max-width: 1000px) {
            margin-top: 0;
            flex-direction: column;
        }

        .left {
            width: 540px;
            height: 574px;
            position: relative;
            background: url("../../assets/teamDots.png") no-repeat 100%;
            background-size: 409px 318px;
            flex-shrink: 0;

            @media screen and (max-width: 600px) {
                width: 100%;
                height: auto;
                background: 0 0;
            }

            .image {
                position: absolute;
                border-radius: 6px;
                box-shadow: 0 24px 24px 0 rgba(0, 0, 0, 0.05), 0 2px 2px 0 rgba(0, 0, 0, 0.05), 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 8px 8px 0 rgba(0, 0, 0, 0.05), 0 16px 16px 0 rgba(0, 0, 0, 0.05);
                background-repeat: no-repeat;
                background-position: 50%;
                background-size: cover;
                background-color: #fff;
                overflow: hidden;
                transition: transform 0.5s ease-out;
            }

            .logo {
                position: absolute;
                border-radius: 6px;
                box-shadow: 0 24px 24px 0 rgba(0, 0, 0, 0.05), 0 2px 2px 0 rgba(0, 0, 0, 0.05), 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 8px 8px 0 rgba(0, 0, 0, 0.05), 0 16px 16px 0 rgba(0, 0, 0, 0.05);
                width: 100px;
                height: 100px;
                background: #fff url("../../assets/Main-logo.png") no-repeat 50%;
                background-size: contain;
                right: 20px;
                top: 53px;

                @media screen and (max-width: 600px) {
                    width: 80px;
                    height: 80px;
                    top: unset;
                    bottom: -30px;
                    left: 0;
                }
            }

            .image-1 {
                background-image: url("../../assets/Teamslider/1.png");
                width: 262px;
                height: 320px;
                top: 0;
                left: 0;

                @media screen and (max-width: 600px) {
                    display: none;
                }
            }

            .image-2 {
                background-image: url("../../assets/team2.png");
                width: 390px;
                height: 243px;
                top: 210px;
                left: 93px;

                @media screen and (max-width: 600px) {
                    position: static;
                    width: calc(100% - 20px);
                    height: 270px;
                    margin-left: 20px;
                }
            }

            .image-3 {
                background-image: url("../../assets/Teamslider/2.jpeg");
                width: 182px;
                height: 182px;
                bottom: 0;
                left: 40px;

                @media screen and (max-width: 600px) {
                    display: none;
                }
            }
         }

         .content {
             width: 500px;
             margin-right: 18px;
             margin-left: 32px;
             font-family: 'Poppins', sans-serif;

            @media screen and (max-width: 600px) {
                margin-top: 78px;
            }

            @media screen and (max-width: 1000px) {
                width: 100%;
                margin: 40px 0 0;
                padding: 0;
            }

            h3 {
                font-size: 32px;
                font-weight: 500;
                line-height: 44px;
                color: #040222;

                @media screen and (max-width: 600px) {
                    margin-top: 20px;
                    font-size: 24px;
                    line-height: 32px;
                }
            }

            p {
                font-size: 20px;
                color: #434159;
                line-height: 30px;
                margin-top: 15px;

                @media screen and (max-width: 600px) {
                    font-size: 17px;
                    line-height: 24px;
                    margin-top: 32px;
                }
            }
         }
    }
}

.secondSection {
    margin-top: 50px;
    margin-bottom: 70px;
}


.thirdSection {
    text-align: center;

    .link {
    text-decoration: none;
    font-size: 22px;
    cursor: pointer;
    color: #5d21d2;
    font-weight: 500;
    display: inline-block;
    margin-top: 45px;
    margin-bottom: 50px;
    padding: 8px 0 6px 40px;
    background: url("https://d33wubrfki0l68.cloudfront.net/13cf4aa89698d3d2dbee8f938c09b2298509a18e/a7400/_nuxt/img/instagram-icon.6ef3e15.svg") no-repeat 0;
    border: none;
    line-height: 1;
    font-family: 'Poppins', sans-serif;
    }
    
    .slick-slide {
        margin-bottom: -100px;
        img {
            height: 540px;
            padding-right: 15px;

            @media screen and (max-width: 800px) {
                height: 420px;
                padding-right: 10px;
            }

            @media screen and (max-width: 600px) {
                height: 300px;
                padding-right: 8px;
            }
            
        }
    }
}